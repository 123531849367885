<template>
  <div>
    <div
      v-if="showBgOverlay"
      class="bg-overlay"
      @click="closePopup('BGClose', popupList[currentPopupIndex])"
    />
    <div>
      <div v-for="popup in popupList" :key="popup.id">
        <div
          :class="{ 'popup-container': true }"
          v-if="popupVisible === popup.id"
          @click.stop="closePopup('BGClose', popup)"
        >
          <div class="popup-image" @click="preventBGClose">
            <button class="close-button" @click.stop="closePopup('XButtonClose', popup)">
              X
            </button>
            <a
              v-if="popup.imgLink"
              :href="popup.imgLink"
              @click.stop="closePopup('ImageClick', popup, popup.imgLink)"
            >
              <img id="mainImage" :src="popup.mainImage" />
            </a>
            <img v-else id="mainImage" :src="popup.mainImage" />
            <div
              v-html="popup.subImageDescription"
              ref="subImageDescription"
              id="subImageDescriptionRef"
            />
            <div v-html="popup.description" />
            <a
              v-for="button in popup.buttons"
              :key="button.id"
              :href="button.link"
              v-html="button.description"
              @click.stop="closePopup('ButtonClick', popup, button.link)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicPopups from '../../../../services/Api/dynamicPopups';

const mainImages = [
  {
    typeId: 1,
    width: 420,
    height: 800,
  },
  {
    typeId: 2,
    width: 500,
    height: 500,
  },
  {
    typeId: 3,
    width: 350,
    height: 210,
  },
];

const BREAKPOINTS = {
  MOBILE_SMALL: 360,
  TABLET: 768,
};

const FONT_SIZES = {
  SMALL: 12,
  MEDIUM: 16,
  LARGE: 18,
};

const POPUP_TYPES = {
  MODAL: 1,
  DIALOG: 2,
  BOTTOM_SHEET: 3,
};

const ACTION_TYPES = {
  X_BUTTON_CLOSE: '1',
  BG_CLOSE: '2',
  BUTTON_CLICK: '3',
};

export default {
  data() {
    return {
      popupVisible: null,
      currentPopupIndex: 0,
      popupList: [],
      popupType: '',
    };
  },
  watch: {
    popupType: {
      immediate: true,
      handler(value) {
        document.body.style.overflow = value == 1 || value == 2 ? 'hidden' : 'auto';
      },
    },
    $route() {
      if (this.$route.fullPath == '/secure/feed') {
        this.fetchPopups();
      }
    },
  },
  computed: {
    showBgOverlay() {
      return (
        this.popupVisible !== null &&
        (this.popupType === POPUP_TYPES.MODAL || this.popupType === POPUP_TYPES.DIALOG)
      );
    },
  },
  methods: {
    addFontSizeToButtons() {
      const screenWidth = window.innerWidth;
      const fontSize =
        screenWidth < BREAKPOINTS.MOBILE_SMALL
          ? FONT_SIZES.SMALL
          : screenWidth < BREAKPOINTS.TABLET
          ? FONT_SIZES.MEDIUM
          : FONT_SIZES.LARGE;
      const buttons = document.getElementsByClassName('btn');

      for (const button of buttons) {
        const currentStyle = button.style.cssText;
        button.style.cssText =
          currentStyle +
          ` font-size: ${fontSize}px; ${
            screenWidth < BREAKPOINTS.MOBILE_SMALL ? 'padding-top: 2px; padding-bottom: 2px;' : ''
          }`;
      }
    },

    calculateMobileRatio(mainImageSize) {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      const { width, height } = mainImageSize;
      const ratioWidth = screenWidth / width;
      const ratioHeight = screenHeight / height;

      return {
        ratioHeight: ratioHeight > 1 ? 1 : ratioHeight > ratioWidth ? ratioWidth : ratioHeight,
        ratioWidth: ratioWidth > 1 ? 1 : ratioWidth,
      };
    },
    updateStyles(stringElement, mobileRatio) {
      if (!stringElement) return '';

      const modifiedElementString = stringElement.replace(
        /(top|left|font-size|width|height):\s*(\d+(\.\d+)?)px;/g,
        (match, p1, p2) => {
          let newValue;

          if (p1 === 'top' || p1 === 'height') {
            newValue = parseFloat(p2) * mobileRatio.ratioHeight;
          } else if (p1 === 'left' || p1 === 'width') {
            newValue = parseFloat(p2) * mobileRatio.ratioWidth;
          } else if (p1 === 'font-size') {
            newValue = parseFloat(p2) * Math.min(mobileRatio.ratioHeight, mobileRatio.ratioWidth);
          }

          return `${p1}: ${newValue}px;`;
        },
      );

      return modifiedElementString;
    },
    async fetchPopups() {
      if (this.$route.fullPath != '/secure/feed') return;

      const response = await DynamicPopups.getAllDynamicPopup();

      if (!response.data.Data) return;

      this.popupList = response.data.Data.popups;

      if (this.popupList.length > 0) {
        this.addFontSizeToButtons();
        this.popupList?.forEach(async popup => {
          const mainImageSize = mainImages.filter(image => popup.typeId === image.typeId)[0];
          const mobileRatio = this.calculateMobileRatio({
            width: mainImageSize.width,
            height: mainImageSize.height,
          });

          if (popup.buttons) {
            popup.buttons.forEach(button => {
              if (button) {
                button.description = this.updateStyles(button.description, mobileRatio);
              }
            });
          }

          popup.description = this.updateStyles(popup.description, mobileRatio);
          popup.subImageDescription = this.updateStyles(popup.subImageDescription, mobileRatio);
        });

        this.openPopup(0);
      }
    },

    async viewPopups(request) {
      await DynamicPopups.viewlogDynamicPopup(request);
    },

    async openPopup(popupIndex) {
      this.popupVisible = this.popupList[popupIndex].id;

      await this.$nextTick();
      const imgElement = document.getElementById('subImgSrc');

      if (imgElement) {
        imgElement.src = this.popupList[popupIndex].subImage;
      }

      this.popupType = this.popupList[popupIndex].typeId;

      if (this.popupType === 3) {
        const popupContainer = document.querySelector('.popup-container');
        popupContainer.classList.add('popup-type-3');
      }

      this.addFontSizeToButtons();
    },

    closePopup(type, popup, popupLink) {
      if (type === 'BGClose' && this.popupType === POPUP_TYPES.BOTTOM_SHEET) return;

      const popupContainer = document.querySelector('.popup-container');

      if (popupContainer) {
        let actionType;
        if (type === 'XButtonClose') {
          actionType = ACTION_TYPES.X_BUTTON_CLOSE;
        } else if (type === 'BGClose') {
          actionType = ACTION_TYPES.BG_CLOSE;
        } else {
          actionType = ACTION_TYPES.BUTTON_CLICK;
        }

        const request = {
          actionValue: popupLink ?? '',
          actionType,
          dynamicPopupId: popup.id,
        };
        this.popupVisible = null;

        this.viewPopups(request);

        this.currentPopupIndex++;

        if (this.currentPopupIndex < this.popupList.length && !popupLink) {
          this.openPopup(this.currentPopupIndex);
        }

        if (this.popupType === 3) {
          const popupContainer = document.querySelector('.popup-container');
          if (popupContainer) {
            popupContainer.classList.remove('popup-type-3');
          }
        }
      }
    },

    preventBGClose(event) {
      event.stopPropagation();
    },
  },
  created() {
    this.fetchPopups();
  },
};
</script>

<style scoped>
div:first-child {
  --bg-overlay-zindex: 1001;
  --popup-container-zindex: 1001;
}

.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: var(--bg-overlay-zindex);
}

#popup {
  z-index: 1002;
  position: fixed;
  top: 0;
  width: 100%;
  overflow-y: hidden;
}

.popup-container {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: hidden;
  height: 100svh;
  z-index: var(--popup-container-zindex);
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  z-index: var(--popup-container-zindex);
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
  color: white;
  border: none;
}

.popup-image {
  position: relative;
  height: auto;
  max-height: 100%;
  display: flex;
  justify-content: center;
}

.popup-image button {
  color: white;
}

.popup-container.popup-type-3 {
  display: flex;
  align-items: flex-end;
  height: fit-content;
  min-width: max-content;
  max-width: max-content;
  position: absolute;
  bottom: 0;
  margin-left: 50%;
  margin-bottom: 50px;
  transform: translate(-50%, 0%);
}

#mainImage {
  position: relative;
}
</style>
