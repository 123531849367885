<template>
  <div :class="{ 'layout-default': true, 'menu-opened': getMenuStatus }">
    <div class="layout-header-holder">
      <VueAppbar
        :key="$route.name"
        :profileImgSrc="currentUser && currentUser.picture ? currentUser.picture : ''"
        style="background-size: cover"
        :cssStyles="{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          background: `url('${require('@/assets/bg/bg-appbar.svg')}') top left no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%) `,
        }"
      ></VueAppbar>
      <ReportButton v-if="isReportBtnVisible" :class="{ 'colored-btn': isColoredBtn }" />
    </div>
    <DynamicPopup ref="dynamicPopup" v-show="!this.isLoadingPopups && !this.currentPopup.popupId" />
    <NpsSurvey v-if="getVisibleNps" :currentPopup="currentPopup" @closeModal="closeNpsModal" />
    <div id="divLayoutMainHolder" class="layout-main-holder">
      <main id="mainAppEllement">
        <div
          :class="[getBottomBarStatus ? 'main-inner-tab-bar' : 'main-inner']"
          :style="{
            overflow: isHiddenScroll ? 'hidden' : 'auto',
            overflowX: isHiddenFeedItemHorizontalScroll ? 'hidden' : '',
          }"
        >
          <slot />
        </div>
      </main>
    </div>
    <BrandModal></BrandModal>
    <div class="layout-bottom-holder" v-if="getBottomBarStatus">
      <BrandBottomNavigationBar
        id="divBottomNavBarWrapper"
        @virtualVisitEvents="virtualVisitEvents"
      />
    </div>
    <transition name="slide">
      <VueMenu v-if="currentMenuItems" v-show="getMenuStatus" @changedPos="changedPos"></VueMenu>
    </transition>

    <BrandSelectModal
      :showModal="showPrivacyModal"
      @onModalClose="closePlainTextModal('privacy')"
      :title="'Gizllilik Bildirimi'"
      :plainTextModal="true"
      @click="closePlainTextModal('privacy')"
      class="modal"
    >
      <VueContentHolder :padding="[20, 20, 80, 20]">
        <div v-html="privacyModalContent"></div>
      </VueContentHolder>
    </BrandSelectModal>

    <BrandSelectModal
      :showModal="showConsentModal"
      @onModalClose="closePlainTextModal('consent')"
      :title="'Çerez Bilgilendirme'"
      :plainTextModal="true"
      @click="closePlainTextModal('consent')"
      class="cookie-modal"
    >
      <VueContentHolder class="cookie-modal-wrapper" :padding="[10, 10, 0, 10]">
        <VueText class="cookie-modal-wrapper-text" sizeLevel="4" color="grey-80" weightLevel="2"
          >Bu sitede çerezler (ve benzeri teknolojiler) kullanmaktayız.
          <b><strong>Daha fazla bilgi al.</strong></b> Bu çerezler üç kategoriye ayrılmaktadır ve bu
          kategoriler aşağıda açıklanmıştır. Tarafımızdan gelen bu çerezleri kabul edip
          etmeyeceğinize karar verebilirsiniz. &nbsp;&nbsp;<a
            class="privacy"
            @click="openPlainTextModal('privacy')"
            >Gizlilik Bildirimi</a
          ></VueText
        >
        <div class="cookie-modal-actions">
          <a @click="openPlainTextModal('cookie')">Daha Fazla Bilgi Al</a>
          <a @click="acceptCookies()">Kabul Ediyorum</a>
        </div>
      </VueContentHolder>
    </BrandSelectModal>
    <BrandCookiesModal
      @close="closePlainTextModal('cookie')"
      :show.sync="showCookieModal"
    ></BrandCookiesModal>
    <BrandStatusModal />
    <BrandSurveyPopup :isChangePos="changedPosPopup" :show.sync="changedPosPopup">
      <SelectPos :isChangePos="changedPosPopup" @closePopUp="closePopUp" />
    </BrandSurveyPopup>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import BrandSelectModal from '@/components/brand/Modals/BrandModal/BrandSelectModal.vue';
import BrandBottomNavigationBar from '@/components/brand/BrandBottomNavigationBar/BrandBottomNavigationBar.vue';
import VueAppbar from '@/components/shared/VueAppbar/VueAppbar.vue';
import VueMenu from '@/components/VueMenu/VueMenu.vue';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';
import BrandStatusModal from '@/components/brand/Modals/BrandModal/BrandStatusModal.vue';
import { mapGetters } from 'vuex';
import StorageHelper from '@/utils/storageHelper';
import { getScreenCodes, arrTranspiler } from '@/utils/brandUtils.js';
import StorageProps from '@/mixins/storageProps.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import BrandCookiesModal from '@/components/brand/Modals/BrandModal/BrandCookiesModal';
import ReportButton from '@/components/brand/ReportButton.vue';
import BrandSurveyPopup from '@/components/brand/Modals/BrandModal/BrandSurveyPopup.vue';
import { Common, User, StaticContent, Notification } from '@/services/Api/index';
import SelectPos from '@/views/pages/public/authentication/login/SelectPos';
import NpsSurvey from '@/views/pages/secure/NpsSurvey.vue';
import DynamicPopup from '../../../src/views/pages/secure/dynamicPopups/DynamicPopup.vue';
import { STATIC_CONTENT_KEYS } from '@/constants/staticContent.constants';
const FALLBACK_BACKGROUND_IMAGE = require('@/assets/bg/bg-image.png');

export default {
  name: 'LayoutDefault',
  mixins: [StorageProps, gtmUtils],
  props: {
    pageTitle: {
      type: String,
    },
    withBackButton: {
      type: Boolean,
    },
  },
  components: {
    BrandBottomNavigationBar,
    VueAppbar,
    VueMenu,
    BrandModal,
    VueContentHolder,
    BrandSelectModal,
    VueText,
    BrandCookiesModal,
    BrandStatusModal,
    ReportButton,
    BrandSurveyPopup,
    SelectPos,
    NpsSurvey,
    DynamicPopup,
  },

  data() {
    return {
      showConsentModal: false,
      showCookieModal: false,
      showPrivacyModal: false,
      privacyModalContent: '',
      notificationPopupStatus: false,
      changedPosPopup: false,
      currentPopup: {},
      isLoadingPopups: true,
    };
  },
  created() {
    this.initApp();
  },

  mounted() {
    if (this.isAuthenticated) {
      this.fetchNotificationCount();
      this.fetchBackgroundImage();
    }
  },

  methods: {
    setPopupGAEvent(value, eventData) {
      if (value) {
        this.pushDataLayerEvent('pop-up', eventData);
      }
    },
    virtualVisitEvents() {
      this.setPopupGAEvent(true, {
        event: 'cEvent',
        category: 'Pop Up',
        action: 'Click',
        label: 'Şef Dükkanı',
        value: 0,
        isNonInteraction: false,
      });
    },
    async getPopupsInfo() {
      try {
        const { data } = await Common.getPopups(this.getAfterLogin);
        if (data.Data) {
          this.currentPopup = data.Data;
          this.$store.dispatch('app/setPopups', data.Data);
          this.$store.dispatch('auth/setAfterLogin', false);
        }
      } finally {
        this.isLoadingPopups = false;
      }
    },
    setPopupClickEvent(isCancelled) {
      this.pushDataLayerEvent('popup_click', {
        event: 'popup_click',
        popup_location: 'anasayfa',
        popup_cancelled: isCancelled,
        popup_name: 'NPS',
        popup_platform: 'PmAktif',
      });
    },
    closeNpsModal(isCancelled) {
      this.setPopupClickEvent(isCancelled);
      this.currentPopup = {};
    },
    changedPos(value) {
      this.changedPosPopup = value;
    },
    closePopUp(staffValidation) {
      this.changedPosPopup = false;
      if (!staffValidation) return;
      window.location.reload();
    },

    initApp() {
      const cachedMenuData = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
      }).get();
      const cachedChatbotModel = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CHATBOT_MODEL,
      }).get();

      if (!cachedMenuData || !cachedMenuData.timestamp) {
        this.resetMenu();
      } else {
        const currentTime = Date.now();
        const thirtyMinutesInMs = 30 * 60 * 1000;
        const isExpired = currentTime - cachedMenuData.timestamp > thirtyMinutesInMs;

        if (isExpired) {
          this.resetMenu();
        } else {
          this.initMenu(cachedMenuData, false);
        }
      }

      if (!cachedChatbotModel) {
        this.resetChatbot();
      } else {
        this.initChatbot(cachedChatbotModel);
      }
    },

    resetMenu() {
      Common.getMenu().then(res => {
        if (res && res.data && res.data.Data) {
          this.initMenu(res.data.Data, true);
        }
      });
    },

    initMenu(menuData, setStorage) {
      try {
        const userMenu = arrTranspiler(menuData.menuItems);
        const screenCodes = getScreenCodes(menuData.menuItems);
        const flatRoutes = this.getRoutesFlat(this.$router.options.routes);
        const mergedMenuItems = {
          menuItems: this.mergeItems(userMenu, flatRoutes),
          partners: menuData.businessPartners,
          isChangePosVisible: menuData.isChangePosVisible,
        };
        this.$store.dispatch('app/setCurrentMenuItems', mergedMenuItems);
        this.$store.dispatch('app/setMenuItems', mergedMenuItems);
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_SCREEN_CODES }).set(screenCodes);

        if (setStorage) {
          const menuDataWithTimestamp = { ...menuData, timestamp: Date.now() };
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_MENU_ITEMS }).set(
            menuDataWithTimestamp,
          );
        }
      } catch {
        if (!setStorage) {
          this.resetMenu();
        }
      }
    },

    initChatbot(chatbot, setStorage) {
      try {
        if (setStorage) {
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_CHATBOT_MODEL }).set(chatbot);
        }

        const user = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).get();

        User.getChatbotData().then(res => {
          if (res && res.data && res.data.Data) {
            window._sorun_set_variable = JSON.stringify(res.data.Data.chatBotData);
            window._sorun_set_mobile_in_iframe = 'true';
            window._sorun_set_name = chatbot.firstName;
            window._sorun_set_surname = chatbot.lastName;
            window._sorun_set_companyID = chatbot.companyId;
            window._sorun_set_lang = 'tr';
            window._sorun_set_token = chatbot.chatToken;
            window._sorun_set_mobileIframeOnloadDisabled = 'true';
            window.mbHideIcon = true;
            window.MB_CLIENT = user.id;
            window.MB_TOKEN = chatbot.chatToken;
            window.MB_VARIABLE = res.data.Data.chatBotData;
            if (chatbot.poolFilter !== 'undefined') {
              window._sorun_set_poolFilter = chatbot.poolFilter;
            }
            if (chatbot.autoOpen) {
              window._sorun_set_auto_open_delay = 1;
            }
            new StorageHelper({ id: 'ChatbotScript' }).set(chatbot.scriptUrl);
            this.loadScript(chatbot.scriptUrl);
          }
        });
      } catch {
        if (!setStorage) {
          this.resetChatbot();
        }
      }
    },

    resetChatbot() {
      if (!this.currentUser.isImageRecognitionUser) {
        User.getChatbotModel().then(res => {
          if (res && res.data && res.data.Data) {
            this.initChatbot(res.data.Data.chatbot, true);
          }
        });
      }
    },

    openPlainTextModal(modalType) {
      if (modalType === 'privacy') {
        StaticContent.getStaticContent('VeriPolitikalari').then(res => {
          if (res.data.Data && res.data.Data.content) {
            this.privacyModalContent = res.data.Data.content;
            this.showConsentModal = false;
            this.showPrivacyModal = true;
          }
        });
      } else if (modalType === 'cookie') {
        this.showCookieModal = true;
      }
    },
    closePlainTextModal(modal) {
      if (modal === 'consent') {
        this.showConsentModal = false;
      } else if (modal === 'privacy') {
        this.showConsentModal = true;
        this.showPrivacyModal = false;
      } else if (modal === 'cookie') {
        this.showConsentModal = false;
        this.showCookieModal = false;
      }
    },
    acceptCookies() {
      User.setUserCookieSettings({
        functionalCookie: true,
        trackingCookie: true,
        performanceCookie: true,
      }).then(res => {
        this.$store.dispatch('app/setCookieSettings', res.data.Data.cookieSettings);
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_COOKIE_SETTINGS }).set(
          res.data.Data.cookieSettings,
        );
        this.closePlainTextModal('cookie');
      });
    },
    async loadScript(src) {
      return new Promise(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        document.head.append(script);
      });
    },
    getRoutesFlat(routes, output = {}, parentPath = '') {
      routes.forEach(route => {
        const { name, path, children, meta } = route;
        const absolutePath = parentPath.length ? parentPath + '/' + path : path;
        if (meta.screenCode) {
          output[meta.screenCode] = {
            name: name,
            path: path,
            absolutePath: absolutePath,
            meta: meta,
            children: children && children.length ? children : null,
          };
        } else {
          return;
        }

        if (route.children && route.children.length) {
          this.getRoutesFlat(route.children, output, absolutePath);
        }
      });
      return { ...output };
    },
    mergeItems(serviceData, routeData) {
      Object.keys(serviceData).forEach(key => {
        const sc = serviceData[key].screenCode;
        Object.keys(routeData).forEach(subKey => {
          if (routeData[subKey].meta.screenCode === sc) {
            serviceData[key]['absolutePath'] = routeData[subKey].absolutePath;
            serviceData[key]['icon'] = routeData[subKey].meta.icon;

            if (serviceData[key].children) {
              serviceData[key] = {
                ...serviceData[key],
                ...this.mergeItems(serviceData[key].children, routeData),
              };
            }
          }
        });
      });
      return serviceData;
    },
    closeNotificationPopup() {
      this.$store.dispatch('app/setNotificationPopupStatus', false);
    },
    async fetchNotificationCount() {
      try {
        const res = await Notification.getNotificationCount();
        const count = res?.data?.Data?.activeNotificationCount ?? 0;
        const visibility = res?.data?.Data?.notificationsVisibility ?? false;

        this.$store.dispatch('app/setNotificationCount', {
          count,
          visibility,
        });
      } catch (error) {
        console.error('Error fetching notification count:', error);
      }
    },
    fetchBackgroundImage() {
      StaticContent.getStaticContent(STATIC_CONTENT_KEYS.MAIN_PAGE_BACKGROUND)
        .then(res => {
          if (res.data?.Data?.content) {
            const backgroundImage = res.data.Data.content;
            this.$store.dispatch('app/setMainPageBackgroundImage', backgroundImage);
          } else {
            this.$store.dispatch('app/setMainPageBackgroundImage', FALLBACK_BACKGROUND_IMAGE);
          }
        })
        .catch(error => {
          console.error('Failed to fetch main page background image:', error);
          this.$store.dispatch('app/setMainPageBackgroundImage', FALLBACK_BACKGROUND_IMAGE);
        });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.showConsentModal = !this.isCookieConsent;
        }, 0);

        if (this.$route.fullPath == '/secure/feed') {
          this.getPopupsInfo();
        } else {
          if (!this.isLoadingPopups && !this.currentPopup.popupId) {
            this.$refs.dynamicPopup.$emit('show');
          }
        }
      },
    },
    isCookieConsent: {
      handler(last) {
        if (last !== 'NotSet') {
          this.showConsentModal = !last;
        }
      },
    },
    isAuthenticated(newValue) {
      if (newValue) {
        this.fetchNotificationCount();
      }
    },
  },
  computed: {
    ...mapGetters('app', ['getMenuStatus', 'getScrollStatus', 'getNotificationPopupStatus']),
    ...mapGetters('auth', ['getAfterLogin', 'isAuthenticated']),
    isHiddenScroll() {
      return (
        this.$route.name === 'image-recognition-home' ||
        this.$route.path.includes('image-recognition/faq')
      );
    },
    isHiddenFeedItemHorizontalScroll() {
      return (
        this.$route.path.includes('secure/feed') ||
        this.$route.path.includes('secure/settings/profile')
      );
    },
    isReportBtnVisible() {
      return (
        this.$route.path.includes('image-recognition') &&
        !this.$route.path.includes('report-problem')
      );
    },
    isColoredBtn() {
      return this.$route.path.includes('racks');
    },
    getVisibleNps() {
      return this.currentPopup.popupId == 6 && this.$route.fullPath == '/secure/feed';
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/styles';
.layout-default {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.menu-opened {
    height: 100vh;
    overflow: hidden;
  }

  .layout-header-holder {
    background-color: $brand-background-primary-color;
  }

  .layout-bottom-holder {
    bottom: 0;
    height: 50px;
    height: calc(50px + #{$safe-area-inset-bottom});
    z-index: 99999999;
    position: fixed;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 1)
    );
  }
  .layout-main-holder {
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;

    main {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      /*padding-bottom: 50px;*/
      .main-inner {
        padding-bottom: $safe-area-inset-bottom;
        height: 100%;
      }
      .main-inner-tab-bar {
        padding-bottom: calc(#{$bottom-bar-height} + #{$safe-area-inset-bottom});
      }
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
}
.cookie-modal {
  /deep/ .modal-bottom-spacer {
    height: 20px;
  }
  &-wrapper {
    &-text {
      max-height: 250px;
      overflow: auto;
      .privacy {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: palette-space-level('30');
    a {
      text-decoration: underline;
      padding: palette-padding-level(15, 20);
      color: palette-color-level('grey', 40) !important;
      font-size: 14px;
    }
  }
}
.colored-btn {
  background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
  color: #fff;
  right: 15px;
}
</style>
<style></style>
