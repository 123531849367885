<template>
  <div class="menu-list-container">
    <ul class="menu-parent" v-for="parent in getMenuList" :key="parent.screenCode">
      <li @click="onCollapse(parent)">
        <div>
          <div :style="{ 'margin-bottom': getParentMargin(parent) }" class="parent-name">
            <span>{{ getItemName(parent) }}</span>
            <img v-if="getParentIconVisible(parent)" :src="getParentIcon(parent)" />
          </div>
          <div v-for="(child, childIndex) in parent.children" :key="childIndex">
            <ul v-if="parent.isActive" class="menu-child">
              <li @click="menuRoutingHandler(child, parent)">
                <div class="child-name">
                  <img class="child-icon" :src="getChildIcon(child)" />
                  <span>{{ getItemName(child) }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { Common } from '@/services/Api/index';
import StorageHelper from '@/utils/storageHelper';
import StorageProps from '@/mixins/storageProps.js';
import LocationMixin from '@/utils/locationUtils.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import { User } from '@/services/Api/index';

export default {
  name: 'VueMenuList',
  mixins: [StorageProps, LocationMixin, gtmUtils],
  computed: {
    getMenuList() {
      return this.menuList;
    },
  },
  data() {
    return {
      isCollapse: false,
      menuList: [],
    };
  },
  mounted() {
    this.setMenuList();
  },
  methods: {
    getParentIcon(item) {
      return require(item.isActive
        ? '../../assets/icons/minus.svg'
        : '../../assets/icons/plus.svg');
    },

    getChildIcon(child) {
      return child.iconURL;
    },

    getParentMargin(parent) {
      return parent.isActive && parent.children?.length ? '10px' : '';
    },

    getItemName(item) {
      return item.screenCode === 1 && this.isLampUser ? 'Yarışmalar' : item.name;
    },

    getParentIconVisible(parent) {
      return parent?.children?.length;
    },

    setMenuList() {
      const items = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
      }).get();

      this.menuList = items.menuItems?.map(m => {
        return { ...m, isActive: false };
      });
      this.$store.dispatch('app/setMenuItems', this.menuList);
    },

    onCollapse(item) {
      item.isActive = !item.isActive;
      this.menuRoutingHandler(item);
    },

    menuRoutingHandler(menuItem, parentItem) {
      if (
        ![2, 4, 22, 23].includes(menuItem.screenCode) &&
        (menuItem.screenCode == 7 || menuItem.screenCode == 19 || menuItem.webViewURL)
      ) {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('menu_click', {
          section: parentItem?.name,
          content: menuItem.name,
        });
      }

      if (menuItem.screenCode == 23) {
        User.newsessionforChatbot();
      }

      switch (menuItem.screenCode) {
        case 2:
          this.pushDataLayerEvent('ecommerce');
          this.pushDataLayerEvent('yap_kazan', {
            location: 'Menu',
          });
          break;
        case 4:
          this.pushDataLayerEvent('ecommerce');
          this.pushDataLayerEvent('cuzdanlarim', {
            location: 'Menu',
          });
          break;
        case 7:
          Common.getGiftCatalogLink().then(res => {
            const {
              Data: { url },
            } = res.data;
            if (url) {
              window.location = url;
            }
          });
          return;
        case 19:
          if (this.currentUser.isImageRecognitionUser) {
            this.getLocationAndRedirect();
          } else {
            this.$router.push({ name: 'image-recognition-home' });
          }
          return;
        case 22:
          this.pushDataLayerEvent('ecommerce');
          this.pushDataLayerEvent('tutun_akademi', {
            location: 'menu',
          });
          break;
        case 23:
          this.pushDataLayerEvent('ecommerce');
          this.pushDataLayerEvent('sen_sor', {
            location: 'menu',
          });
          break;
        default:
          break;
      }
      if (this.$route.fullPath === menuItem.webViewURL) {
        this.$router.go();
      } else {
        const query = { type: menuItem.name };

        if (menuItem.screenCode == 9) {
          query.isFromRosette = false;
        }

        if (menuItem.webViewURL) {
          this.$router.push({
            path: menuItem.webViewURL,
            query,
          });
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.menu-list-container {
  padding-bottom: 25px;
  .menu-parent {
    border-bottom: 0.5px solid #b4c2d3;
    list-style-type: none;
    padding: 20px 0;
    .parent-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      img {
        width: 25px;
        height: 25px;
        margin: 5px;
      }
    }
  }
  .menu-child {
    list-style-type: none;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 300;
    color: #23303d;
    cursor: pointer;
    .child-name {
      display: flex;
      align-items: center;
    }

    .child-icon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
}
</style>
