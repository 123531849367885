<template>
  <component :is="as" v-if="mergedConfig.isModalOpened || false">
    <div class="modal-wrapper" style="z-index: 999;">
      <div class="modal-header">
        <VueButton
          color="white-100"
          :iconName="btnBackIcon.name"
          :iconSize="{
            width: btnBackIcon.width,
            height: btnBackIcon.height,
          }"
          iconColor="#eb3832"
          :ghost="true"
          target="-1"
          @click="closeModal"
        />
        <div class="modal-header-logo">
          <img src="~@/assets/logos/pmaktif-sm.png" alt="logo" />
        </div>
      </div>
      <div v-if="mergedConfig.imgSrc" class="modal-content img-wrapper">
        <img :src="mergedConfig.imgSrc" alt="img" />
      </div>
      <div v-else class="modal-content">
        <VueIcon
          v-if="mergedConfig.isUploading"
          :iconName="getIcons.time.name"
          :width="getIcons.time.width"
          :height="getIcons.time.height"
        />
        <VueIcon
          v-else-if="mergedConfig.isSuccess"
          :iconName="getIcons.success.name"
          :width="getIcons.success.width"
          :height="getIcons.success.height"
        />
        <VueIcon
          v-else
          :iconName="getIcons.fail.name"
          :width="getIcons.fail.width"
          :height="getIcons.fail.height"
        />
        <VueText sizeLevel="9" class="modal-content-text">
          {{
            mergedConfig.isUploading
              ? 'Fotoğraflar Gönderiliyor Lütfen Bekleyiniz'
              : mergedConfig.isSuccess
              ? 'Fotoğraflarınız Başarıyla Gönderildi'
              : 'Üzgünüz, İşleminiz Gerçekleşmemiştir'
          }}
        </VueText>
      </div>
      <div v-if="mergedConfig.imgSrc" class="modal-btn">
        <BrandButton as="div" v-on:click.native="closeModal()" :size="sizes.xxLarge"
          >DEĞİŞTİR</BrandButton
        >
      </div>
      <div v-else class="modal-btn">
        <BrandButton v-if="mergedConfig.isFail" as="a" :size="sizes.xxLarge" @click="goToHome"
          >YENİDEN DENE</BrandButton
        >
        <BrandButton
          v-else
          as="a"
          @click="goToHome"
          :size="sizes.xxLarge"
          :disabled="mergedConfig.isUploading"
          >TAMAM
        </BrandButton>
      </div>
    </div>
  </component>
</template>

<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { COMPONENT_SIZES, ICON_VARIABLES } from '@/constants/component.constants.js';
import { mapGetters } from 'vuex';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';

export default {
  name: 'BrandStatusModal',
  components: {
    VueButton,
    VueText,
    BrandButton,
    VueIcon,
  },
  props: {
    as: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      renderKey: 0,
      disabled: false,
      modalConfig: {},
    };
  },
  watch: {
    mergedConfig: {
      handler: function() {
        this.renderKey += 1;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('app', ['getStatusModalConfig']),
    mergedConfig() {
      return { ...this.modalConfig, ...this.getStatusModalConfig };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    btnBackIcon() {
      return ICON_VARIABLES.arrowLeft;
    },
    getIcons() {
      const { time, success, fail } = ICON_VARIABLES;
      return { time, success, fail };
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('app/setStatusModalConfig', {});
    },
    goToHome() {
      this.$store.dispatch('app/setStatusModalConfig', {});
      this.$store.dispatch('app/setRacks', []);
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.ImageRecognition.path}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-wrapper {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 40;
    top: 0;
    left: 0;
    object-fit: contain;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: #fff;
  }
  &-header {
    display: flex;
    align-items: center;
    padding: 0 palette-space-level(20);
    height: 100%;
    height: 70px;
    &-logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 85px;
      }
    }
  }
  &-content {
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 22%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    z-index: 1;
    word-break: break-word;
    padding: palette-space-level('20');

    &-text {
      text-align: center;
      line-height: 1.25;
      font-weight: normal;
      margin-top: 50px;
    }
    &-wrapper {
      display: flex;
      padding: palette-space-level('20');

      &-nospace {
        padding: 0 !important;
      }
    }

    &-icon {
      padding: 20px;
    }
  }
}
.img-wrapper {
  margin-top: 0;
  /* max-height: calc(100vh - 150px); */
  img {
    max-height: 100%;
  }
}
</style>
