<template>
  <div
    v-if="getVisibleMarathon"
    :style="!getVisibleMarathon ? { zIndex: 10 } : { zIndex: 24 }"
    class="notification-popup-contentmarathon notification-popup-contentmarathon-marathon"
  >
    <div class="close" @click="closePopup('XButtonClose')" />
    <div class="goPlayWin" @click="playWinRoute()">
      <img :src="getMarathonImage" @load="onImgLoad" alt="notification icon" />
      <div v-if="isLoaded" class="marathon-popup-point">{{ getMarathonPoint }}&nbsp;puan</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RoutesPlayAndWin from '@/router/routes/secure/RoutesPlayAndWin';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'Marathon',
  mixins: [gtmUtils],
  computed: {
    ...mapGetters('app', ['getPopups']),
    getVisibleMarathon() {
      return this.getPopups.popupId == 2 && this.$route.fullPath == '/secure/feed';
    },
    getMarathonImage() {
      return this.getPopups?.popupDetail?.image ?? '';
    },
    getMarathonPoint() {
      return this.getPopups?.popupDetail?.point ?? '';
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    onImgLoad() {
      return (this.isLoaded = true);
    },
    playWinRoute() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('odül_yagmuru', {
        location: 'pop_up',
      });
      let playWinRoute = `${RoutesRoot.Secure.path}/${RoutesSecure.PlayAndWin.path}/${RoutesPlayAndWin.PlayAndWinDetail.path}`;
      this.closePopup('LinkClick');
      this.$router.push(playWinRoute);
    },
    closePopup(action) {
      const eventData = {
        event: 'cEvent',
        category: 'Pop Up',
        action: 'Close',
        label: 'Marathon',
        value: 0,
        isNonInteraction: false,
      };
      this.$emit('closePopup', eventData, { type: action });
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-popup {
  position: absolute;
  top: 0;
  right: -5px;
  left: -5px;
  bottom: 0;
  background-color: #fff;
  z-index: -1;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &-contentmarathon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 323px;
    .close {
      position: absolute;
      left: -2px;
      top: -10px;
      color: #fff;
      width: 45px;
      height: 45px;
      cursor: pointer;
      transition: opacity 0.2s ease;
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: 15px;
        width: 16px;
        height: 2px;
        background-color: #fff;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(135deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
    .goPlayWin {
      cursor: pointer;
    }
  }
}
.marathon-popup-point {
  position: absolute;
  top: 115px;
  left: 50%;
  color: white;
  font-weight: bold;
  font-size: 40px;
  transform: translateX(-50%);
}
</style>
