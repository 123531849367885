<template>
  <div class="modal-backdrop" v-show="show">
    <transition name="slide">
      <div class="modal-wrapper" v-if="show">
        <div class="modal-header-with-actions">
          <VueText sizeLevel="7" weightLevel="3" color="white-100">Çerez Ayarları</VueText>

          <div class="header-buttons" @click.prevent="toggleSelect('all')">
            <VueText sizeLevel="3" weightLevel="3" color="white-100">Tümünü Seç</VueText>

            <VueIcon :iconName="modalIcons.circle.name" v-show="!allSelected" iconColor="#ffffff" />
            <VueIcon v-show="allSelected" :iconName="modalIcons.tick.name" iconColor="#ffffff" />
          </div>
        </div>

        <div class="modal-body">
          <VueContentHolder
            v-if="cookieTypes && cookieTypes.length > 0"
            class="modal-cookies-wrapper"
            :padding="[20, 20, 20, 20]"
          >
            <div class="cookie-header">
              <VueText sizeLevel="4" weightLevel="3" class="cookie-header-title">
                Çerezler veya web işaretçileri nedir?
              </VueText>
              <VueText sizeLevel="4" color="grey-30" weightLevel="2">
                Çerezler, izleme teknolojilerinin bir örneğidir. Bunlar dijital bir platformu
                ziyaret ettiğinizde bilgisayarınıza yerleşen ve daha sonra aşağıda belirtilen
                amaçlarla bilgisayarınızın kimliğini tespit etme amacıyla kullanılan küçük metin
                dosyalarıdır. Çerezler "birinci taraf çerezleri" adı verilen bir dijital platformun
                sahibi tarafından yerleştirilir. Diğer kişilerce yerleştirilen çerezler "üçüncü
                taraf çerezleridir". Üçüncü taraf çerezleri, üçüncü tarafın dijital platform
                üzerinde veya onun aracılığıyla (analiz, reklam ve video gibi) özellikler ve
                işlevler sunmasına imkan verir. Üçüncü taraf çerezleri yerleştiren taraflar
                cihazınız dijital platformu ziyaret ettiğinde ve diğer dijital platformları ziyaret
                ettiğinde cihazını tanıyabilir. Bu sitede çerezler (ve benzeri teknolojiler)
                kullanmaktayız. Bu çerezler üç kategoriye ayrılmaktadır ve bu kategoriler aşağıda
                açıklanmıştır. Tarafımızdan gelen bu çerezleri kabul edip etmeyeceğinize karar
                verebilirsiniz.</VueText
              >
            </div>

            <div class="modal-body-single-item" v-for="c in cookieTypes" :key="c.type">
              <div class="modal-body-action-row" @click.prevent="toggleSelect(c.key)">
                <VueText sizeLevel="4" weightLevel="3" color="grey-100">{{ c.name }}</VueText>

                <VueIcon
                  v-show="!postModel[c.key]"
                  :iconName="modalIcons.circle.name"
                  iconColor="#000000"
                />
                <VueIcon
                  v-show="postModel[c.key]"
                  :iconName="modalIcons.tick.name"
                  iconColor="#000000"
                />
              </div>
              <VueSpacer level="20" />
              <VueText sizeLevel="4" color="grey-30" weightLevel="2">{{ c.description }}</VueText>
              <VueSpacer level="20" />

              <VueText sizeLevel="3" weightLevel="4">Cookies Used</VueText>
              <details v-for="cd in c.cookies" :key="cd.name" class="collapsible-cookies">
                <summary>{{ cd.name }} </summary>
                <p>
                  {{ cd.description }}
                </p>
              </details>

              <VueSpacer level="20" />
            </div>
          </VueContentHolder>
          <VueSpacer class="modal-bottom-spacer" level="20" />
        </div>

        <div class="submit-wrapper">
          <BrandButton
            :outlined="true"
            :size="sizes.xxLarge"
            :contentAlignment="constants.flexAlignment.center"
            type="submit"
            :padding="{ vertical: 15, horizontal: 0 }"
            @click="handleSubmit"
            >Kapat</BrandButton
          >
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { Common, User } from '@/services/Api/index';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import StorageProps from '@/mixins/storageProps.js';
import { convertToObjectBy } from '@/mixins/arrayUtils';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'BrandCookiesModal',
  components: { VueText, BrandButton, VueSpacer, VueIcon, VueContentHolder },
  mixins: [StorageProps],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      renderKey: 1,
      allSelected: false,
      cookieTypes: null,

      postModel: {
        functionalCookie: true,
        trackingCookie: true,
        performanceCookie: true,
      },
    };
  },
  watch: {
    allSelected: {
      handler(value) {
        this.postModel.trackingCookie = value;
        this.postModel.performanceCookie = value;
      },
    },
    show: {
      handler(value) {
        this.$store.dispatch('app/setBottomBarStatus', !value);
        if (value) {
          Common.getCookieDetails().then(res => {
            this.cookieTypes = res.data.Data.cookieTypes.map(obj => {
              const item = { ...obj, key: '' };
              item.name =
                item.type == 1
                  ? 'İşlemsel Çerezler'
                  : item.type == 2
                  ? 'Takip Çerezleri'
                  : 'Performans Çerezleri';
              item.key =
                item.type == 1
                  ? 'functionalCookie'
                  : item.type == 2
                  ? 'trackingCookie'
                  : 'performanceCookie';

              return item;
            });
          });
          let userCookieSettings = convertToObjectBy(this.getCookieSettings.cookieTypes, 'type');
          this.postModel.trackingCookie = userCookieSettings[2].enable;
          this.postModel.performanceCookie = userCookieSettings[3].enable;
        }
      },
    },
  },

  methods: {
    // Cookie is reading.
    getCookie(cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    // New cookie is writing.
    setCookie(cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
    handleSubmit() {
      User.setUserCookieSettings(this.postModel).then(res => {
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_COOKIE_SETTINGS }).set(
          res.data.Data.cookieSettings,
        );
        this.$store.dispatch('app/setCookieSettings', res.data.Data.cookieSettings);
      });
      if (!this.postModel.performanceCookie) {
        let performanceCookies = this.getCookieSettings.cookieTypes[2].cookies;
        for (let index = 0; index < performanceCookies.length; index++) {
          this.setCookie(performanceCookies[index], this.getCookie(performanceCookies[index]), -1);
        }
      }
      if (!this.postModel.trackingCookie) {
        let trackingCookies = this.getCookieSettings.cookieTypes[1].cookies;
        for (let index = 0; index < trackingCookies.length; index++) {
          this.setCookie(trackingCookies[index], this.getCookie(trackingCookies[index]), -1);
        }
      }
      this.$emit('update:show', false);
      this.$emit('close');
    },
    toggleSelect(e) {
      if (e === 'all') {
        this.allSelected = !this.allSelected;
      } else if (e !== 'functionalCookie') {
        this.postModel[e] = !this.postModel[e];
      }
    },
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    modalIcons() {
      return {
        circle: ICON_VARIABLES.circle,
        tick: ICON_VARIABLES.rememberMe,
        caret: ICON_VARIABLES.chevronDownAlt,
        cross: ICON_VARIABLES.cross,
      };
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/_animations.scss';

.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 40;
  top: 0;
  left: 0;
  object-fit: contain;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.7);
  .modal-wrapper {
    position: fixed;
    bottom: 0;
    z-index: 40;
    width: 100%;

    .modal-header-with-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: palette-space-level('15') palette-space-level('20');
      width: 100%;
      background-color: palette-color-level('grey', '40');
      .header-buttons {
        p {
          padding-right: palette-space-level('10');
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .modal-body {
      background-color: palette-color-level('white', '100');
      border-radius: 0 !important;
      .modal-cookies-wrapper {
        max-height: 400px;
        overflow: auto;
        .cookie-header {
          &-title {
            padding-bottom: palette-space-level(10);
          }
          padding-bottom: palette-space-level(25);
        }
        .modal-body-single-item {
          display: flex;
          flex-direction: column;
          .collapsible-cookies {
            summary {
              padding: 5px;
            }

            p {
              padding: 5px 5px 5px 10px;
            }
          }
          .modal-body-action-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            padding-bottom: palette-space-level('10');
            border-bottom: 1px solid palette-color-level('grey', '50');
          }
        }
      }
    }

    .submit-wrapper {
      border-top: 1px solid palette-color-level('grey', 30);
    }
  }
}
</style>
