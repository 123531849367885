<template>
  <router-link :to="{ name: 'ReportProblem' }" class="report-btn ">Sorun Bildir</router-link>
</template>

<script>
export default {
  name: 'ReportButton',
};
</script>
<style lang="scss">
.report-btn {
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 9;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  vertical-align: middle;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  outline: none;
  border: none;
  text-decoration: none;
  color: #23303d;
  font-size: 14px;
}
</style>
