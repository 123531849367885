import UserAgentHelper from '@/utils/agentUtils';
const LocationMixin = {
  methods: {
    getLocationAndRedirect() {
      let clientInfo = new UserAgentHelper();
      if (!clientInfo.isChrome) {
        const modalConfig = {
          isModalOpened: true,
          modalType: 'alert',
          alertType: 'warn',
          modalConfirmationText: '',
          modalText: 'Fotoğraf çekmek için lütfen chrome uygulamasını kullanınız.',
          firstButtonText: 'TAMAM',
        };
        this.$store.dispatch('app/setModalConfig', modalConfig);
      } else {
        this.$store.dispatch('app/setOverlayVisibility', true);
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.setPosition, this.showError);
        } else {
          alert('Geolocation is not supported by this browser.');
        }
      }
    },
    setPosition(position) {
      if (position) {
        this.$store.dispatch('app/setOverlayVisibility', false);
        this.$store.dispatch(
          'app/setLocation',
          `${position.coords.latitude},${position.coords.longitude}`,
        );
        this.$router.push({
          name: 'Main',
        });
      }
    },
    showError(error) {
      this.$store.dispatch('app/setOverlayVisibility', false);
      switch (error.code) {
        case error.PERMISSION_DENIED:
          {
            const modalConfig = {
              isModalOpened: true,
              modalConfirmationText: '',
              modalText:
                'Uygulamayı kullanabilmeniz için konum erişimi gerekiyor. <br/> Tarayıcı ayarlarından konum iznini aktif ediniz.',
              firstButtonText: 'TAMAM',
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
          }
          break;
        case error.POSITION_UNAVAILABLE:
          {
            const modalConfig = {
              isModalOpened: true,
              modalConfirmationText: '',
              modalText: 'Konum bilgisi alınamadı. <br/> Lokasyon bilgisine erişilemiyor.',
              firstButtonText: 'TAMAM',
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
          }
          break;
        case error.TIMEOUT:
          {
            const modalConfig = {
              isModalOpened: true,
              modalConfirmationText: '',
              modalText:
                'Konum bilgisi alınamadı. <br/> Lokasyon bilgisi alınırken zaman aşımı olıştu.',
              firstButtonText: 'TAMAM',
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
          }
          break;
        case error.UNKNOWN_ERROR:
          {
            const modalConfig = {
              isModalOpened: true,
              modalConfirmationText: '',
              modalText: 'Konum bilgisi alınamadı. <br/> Bilinmeyen bir hata oluştu',
              firstButtonText: 'TAMAM',
            };
            this.$store.dispatch('app/setModalConfig', modalConfig);
          }
          break;
      }
    },
  },
};
export default LocationMixin;
