<template>
  <LayoutAuthForm id="divSelectPosWrapper" class="select-pos-wrapper">
    <VueForm
      v-if="getPosList && getPosList.length"
      :bodyPadding="['0', '10']"
      :formWrapperClass="formWrapperClass"
      :footerSlotClass="footerSlotClass"
    >
      <VueListView class="branch-list" dir="column">
        <VueContentHolder :padding="[10, 0, 0]" v-for="item in getPosList" :key="item.id">
          <VueButton
            :size="sizes.xLarge"
            :contentAlignment="constants.flexAlignment.center"
            :isSingleLine="false"
            outlined
            class="single-pos-item"
            :class="{ 'selected-pos': selectedPos.id == item.id }"
            @click.prevent="setPos(item)"
            >{{ item.posName }}</VueButton
          >
        </VueContentHolder>
      </VueListView>

      <div slot="footer">
        <BrandButton
          :size="sizes.xxLarge"
          :class="footerButtonClass"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :disabled="!selectedPos.id"
          @click.prevent="submitPos"
          >DEVAM ET</BrandButton
        >
      </div>
    </VueForm>
  </LayoutAuthForm>
</template>
<script>
import LayoutAuthForm from '@/views/layouts/LayoutAuthForm.vue';
import VueListView from '@/components/shared/VueListView/VueListView.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { mapGetters } from 'vuex';
import { Account } from '@/services/Api/index';
import AccountMixin from '@/utils/accountUtils.js';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'SelectPos',
  components: {
    LayoutAuthForm,
    VueButton,
    BrandButton,
    VueForm,
    VueListView,
    VueContentHolder,
  },
  props: {
    isChangePos: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      submitted: false,
      selectedPos: {},
      formWrapperClass: 'select-pos-form',
      footerSlotClass: 'select-pos-form--footer',
      footerButtonClass: 'select-pos-form--footer-button',
    };
  },
  mixins: [AccountMixin],
  mounted() {
    if (!this.getPosList) {
      this.$router.push(RoutesLogin.SelectLoginType.path);
    }

    this.getPosList.forEach(f => {
      if (f.isCurrentPos) {
        this.selectedPos = f;
      }
    });
  },
  computed: {
    ...mapGetters('auth', ['getPosList']),
    ...mapGetters('app', ['getChangedPosToken']),
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    routes() {
      return {
        auth: RoutesAthentication,
      };
    },
    getIcon() {
      return ICON_VARIABLES.eye;
    },
  },
  methods: {
    setPos(item) {
      this.selectedPos = item;
    },
    async submitPos() {
      if (this.selectedPos.id) {
        if (this.isChangePos) {
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(
            this.getChangedPosToken,
          );
        }

        await Account.selectedPos({ posId: this.selectedPos.id }).then(res => {
          const { Data } = res ? res.data : { Data: null };

          if (Data) {
            if (Data.user && !Data.user.picture) {
              Data.user.picture = process.env.VUE_APP_USER_PHOTO_PLACEHOLDER;
            }

            const response = { ...Data, pos: [this.selectedPos] };

            const { token, refreshToken, user, pos, staffValidation } = response;

            this.$store.dispatch('auth/setAuthData', response).then(() => {
              if (token) {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(token);
              }
              if (refreshToken) {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).set(
                  refreshToken.token,
                );
              }
              if (user) {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).set(user);
              }

              if (pos) {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).set(pos[0]);
                this.accountLogin(pos[0].posChannel, staffValidation, user);
              }
            });
            this.$emit('closePopUp', staffValidation);
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
// .layout-holder {
//   min-height: auto;
//   height: 100%;
// }
.select-pos-form {
  form {
    position: relative;
  }
  // min-height: auto;
}

::v-deep {
  .select-pos-form--footer {
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: unset;
  }
  .select-pos-form--footer-button {
    position: absolute;
    bottom: 0;
    opacity: 1;
  }
}

.selected-pos {
  background-color: palette-color-level('green', 30) !important;
  color: palette-color-level('white', 100) !important;
}

.single-pos-item {
  margin-bottom: palette-space-level(20);
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.branch-list {
  height: calc(100vh - 180px);
  padding-right: palette-space-level('10');
  padding-left: palette-space-level('10');
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
  padding-bottom: 50px;
}
.select-pos-wrapper {
  width: 300px;
}
</style>
