<template>
  <div v-if="getVisibleRosettePopup">
    <div class="notification-popup notification-popup-dowin">
      <VueIcon
        :iconName="getIcon.cross.name"
        :width="getIcon.cross.width"
        :height="getIcon.cross.height"
        @click.prevent="closePopup('XButtonClose')"
        class="popup-close"
        iconColor="#ffffff"
      ></VueIcon>
      <div @click="goToRosette">
        <img :src="getRosettePopupImage" @load="onImgLoad" alt="rosettepopup" />
        <div v-if="isLoaded && isContainerPopup" class="rosette-popup-month">
          {{ getCurrentMonth }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';

export default {
  name: 'RosettePopup',
  components: {
    VueIcon,
  },
  computed: {
    ...mapGetters('app', ['getPopups']),
    getIcon() {
      return {
        cross: ICON_VARIABLES.cross,
      };
    },
    getVisibleRosettePopup() {
      const allowedPopupIds = [8, 9, 10, 11, 12, 13, 14];
      const isFeedPage = this.$route.fullPath === '/secure/feed';

      return allowedPopupIds.includes(this.getPopups.popupId) && isFeedPage;
    },
    getRosettePopupDetail() {
      return this.getPopups?.popupDetail;
    },
    getRosettePopupImage() {
      return this.getPopups?.popupDetail?.image ?? '';
    },
    getCurrentMonth() {
      return this.months[new Date().getMonth()];
    },
    isContainerPopup() {
      return this.getPopups.popupId == 8;
    },
  },
  data() {
    return {
      isLoaded: false,
      months: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
    };
  },
  methods: {
    setEventData(isClick) {
      return {
        action: isClick ? 'Click' : 'Close',
        isNonInteraction: false,
        label: 'RosettePopup',
        category: 'Pop Up',
        event: 'cEvent',
        value: 0,
      };
    },
    onImgLoad() {
      return (this.isLoaded = true);
    },
    goToRosette() {
      if (this.getPopups.popupId === 11) {
        this.closePopup('XButtonClose');
      } else {
        if (this.getPopups.popupId === 10) {
          window.location = this.getPopups.popupDetail.url;
        } else {
          this.$router.push(this.getPopups.popupDetail.url);
        }
        this.closePopup('LinkClick');
      }
    },
    closePopup(action) {
      const eventData = this.setEventData(false);
      this.$emit('closePopup', eventData, { type: action });
    },
  },
};
</script>
<style lang="scss" scoped>
.notification-popup {
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  top: 50%;
  left: 50%;
  width: 80%;
}
.popup-close {
  position: absolute;
  margin: 10px;
  width: 12px;
}
.rosette-popup-month {
  transform: translateX(-50%);
  position: absolute;
  font-size: 15px;
  color: white;
  top: 24px;
  left: 23%;
}
</style>
