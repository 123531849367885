<template>
  <div id="divMenuWrapper" class="menu-wrapper" @click="closeMenu">
    <transition name="innerSlide">
      <div class="menu" v-show="getMenuStatus">
        <VueAppbar
          isMenuBar
          :title="getMenuTitle"
          :profileImgSrc="currentUser ? currentUser.picture : ''"
          :hasBackButton="!isMenuRoot"
          :triggerBackButton="false"
          @backButtonEvent="getParentMenuList()"
          class="appbar-sticky"
          :cssStyles="{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            background: `url('${require('@/assets/bg/bg-appbar.svg')}') top left no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%)`,
          }"
        >
        </VueAppbar>
        <div ref="menuBody" class="menu-body">
          <div id="anchMenuTop"></div>
          <div
            class="menu-container brand-list"
            v-if="isMenuRoot && currentMenuItems.partners && !isImageRecognitionUser"
          >
            <VueText @click="goToPartner" sizeLevel="8" weightLevel="1" class="menuBrandTitle">{{
              currentMenuItems.partners.length > 1 ? 'İş Ortaklarım' : 'İş Ortağım'
            }}</VueText>
            <div class="menuBrandWrapper">
              <a
                v-for="partner in currentMenuItems.partners"
                @click="clickPartner(partner)"
                :key="partner.code"
                class="menuBrandItem"
              >
                <BrandLogo :height="90" :image="partner.image" />
              </a>
            </div>
          </div>
          <div class="menu-container menu-list" v-if="currentMenuItems.menuItems">
            <VueMenuList />
            <div
              v-if="currentMenuItems.isChangePosVisible"
              @click.stop="processChangedPos"
              class="pos-change"
            >
              Şube Değiştir
            </div>
            <VueButton
              class="logout-btn"
              @click.native.prevent="processLogout"
              :outlined="true"
              :iconName="getIcon('onOff').name"
              :iconColor="'#c60000'"
              >Çıkış Yap</VueButton
            >
            <div class="insider" id="menu"></div>
          </div>
          <div class="menu-container footerWrapper">
            <div class="footerTopRow">
              <BrandLogo
                :hasBorder="false"
                :width="110"
                :height="22"
                :image="require('@/assets/logos/pmaktif-sm.png')"
              />
              <BrandLogo
                :hasBorder="false"
                :width="94"
                :height="50"
                :image="require('@/assets/logos/philip-morris-logo.png')"
              />
            </div>
            <div class="footerBottomRow">
              <p class="footerBottomBox">SİGARA İÇMEK ÖLDÜRÜR</p>
              <div class="footer-links-wrapper">
                <a class="footerLink" @click="openPlainTextModal(1)">Yasal Şartlar</a>
                <a class="footerLink" @click="openPlainTextModal(2)">Gizlilik Bildirimi</a>
                <a class="footerLink" @click="openPlainTextModal(3)">Çerez Ayarları</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <BrandSelectModal
      :showModal="isLegalWarningModalOpened"
      @onModalClose="closePlainTextModal"
      :title="'Yasal Şartlar'"
      :plainTextModal="true"
      @click="closePlainTextModal"
      class="modal"
    >
      <VueContentHolder :padding="[20, 20, 80, 20]">
        <div v-html="legalWarningHtml"></div>
      </VueContentHolder>
    </BrandSelectModal>

    <BrandSelectModal
      :showModal="isDataPolicyModalOpened"
      @onModalClose="closePlainTextModal"
      :title="'Gizllilik Bildirimi'"
      :plainTextModal="true"
      @click="closePlainTextModal"
      class="modal"
    >
      <VueContentHolder :padding="[20, 20, 80, 20]">
        <div v-html="dataPolicyHtml"></div>
      </VueContentHolder>
    </BrandSelectModal>

    <BrandCookiesModal :show.sync="isCookieModalOpened"></BrandCookiesModal>
  </div>
</template>

<script>
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import BrandSelectModal from '@/components/brand/Modals/BrandModal/BrandSelectModal.vue';
import BrandCookiesModal from '@/components/brand/Modals/BrandModal/BrandCookiesModal';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueAppbar from '@/components/shared/VueAppbar/VueAppbar.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { mapGetters } from 'vuex';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import StorageProps from '@/mixins/storageProps.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import LoginMixin from '@/mixins/login.js';
import StaticContent from '@/services/Api/staticContents';
import LocationMixin from '@/utils/locationUtils.js';
import AccountMixin from '@/utils/accountUtils.js';
import { Account } from '@/services/Api/index';
import VueMenuList from './VueMenuList.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';

export default {
  name: 'VueMenu',
  mixins: [StorageProps, gtmUtils, LoginMixin, LocationMixin, AccountMixin],
  components: {
    VueContentHolder,
    VueText,
    VueAppbar,
    BrandSelectModal,
    BrandCookiesModal,
    BrandLogo,
    VueMenuList,
    VueButton,
  },
  created() {
    // Do not use the currentUser attribute directly as it makes the menu glitch during logout, which erases currentUser
    this.isImageRecognitionUser = this.currentUser.isImageRecognitionUser;
  },
  data() {
    return {
      closeModalInitCount: 0,
      menuBreadCrumb: [],
      currentParent: '',
      isMenuRoot: true,
      dataPolicyHtml: null,
      legalWarningHtml: null,
      isLegalWarningModalOpened: false,
      isDataPolicyModalOpened: false,
      isCookieModalOpened: false,
      isOperationCookieSelected: false,
      isPerformanceCookieSelected: false,
      isTrackingCookieSelected: false,
      isImageRecognitionUser: false,
    };
  },
  watch: {
    getMenuStatus: {
      handler(newVal) {
        if (!newVal) {
          this.$refs.menuBody.scrollTop = 0;
        } else {
          this.pushAds('small');
        }
      },
    },
  },
  computed: {
    pmiRoute() {
      return `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/pmi`;
    },
    pladisRoute() {
      return `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/ulker`;
    },
    peymanRoute() {
      return `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/peyman`;
    },
    horizonRoute() {
      return `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/horizon`;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    modalIcons() {
      return {
        circle: ICON_VARIABLES.circle,
        tick: ICON_VARIABLES.rememberMe,
      };
    },
    getRouteName() {
      return this.$route.name;
    },
    getMenuTitle() {
      if (!this.isMenuRoot) {
        return this.currentParent || this.getRouteName;
      }
      return this.currentUser ? this.currentUser.name : '';
    },
    ...mapGetters('app', ['getMenuStatus']),
  },
  methods: {
    processChangedPos() {
      Account.changedPos().then(res => {
        if (res?.data?.Data) {
          const pos = res.data.Data.pos;
          const posArr = Array.isArray(pos) ? pos : [pos];

          if (posArr.length > 1) {
            this.$store.dispatch('auth/setPosList', posArr).then(() => {
              this.closeMenu('divMenuWrapper');
              this.$emit('changedPos', true);
              this.$store.dispatch('app/setChangedPosToken', res.data.Data.token);
            });
          }
        }
      });
    },
    goToPartner() {
      if (
        this.currentMenuItems.partners.length === 1 &&
        this.currentMenuItems.partners[0].code === 'PMI'
      ) {
        if (this.$route.name !== 'business-partners-home') {
          this.$router.push(this.pmiRoute);
        } else {
          this.$store.dispatch('app/setMenuStatus', false);
        }
      }
    },
    clickPartner(partner) {
      this.pushDataLayerEvent('ecommerce');
      const partnerMap = {
        PMI: 'PMI',
        PLADIS: 'ÜLKER',
        PEY: 'PEYMAN',
        HORIZON: 'HORIZON',
      };
      this.pushDataLayerEvent('business_partner_click', {
        partner: partnerMap[partner.code] || partner.code,
      });

      const routeMap = {
        PMI: this.pmiRoute,
        PLADIS: this.pladisRoute,
        PEY: this.peymanRoute,
        HORIZON: this.horizonRoute,
      };
      const route = routeMap[partner.code];
      if (window.location.pathname === route) return;
      this.$router.push(route);
    },
    closeMenu(e) {
      if (e?.target?.id === 'divMenuWrapper' || e == 'divMenuWrapper') {
        this.$store.dispatch('app/setMenuStatus', false);
      }
    },
    selectAllCookiePreferences() {
      if (
        !this.isOperationCookieSelected ||
        !this.isPerformanceCookieSelected ||
        !this.isTrackingCookieSelected
      ) {
        this.isOperationCookieSelected = true;
        this.isPerformanceCookieSelected = true;
        this.isTrackingCookieSelected = true;
      } else {
        this.isOperationCookieSelected = false;
        this.isPerformanceCookieSelected = false;
        this.isTrackingCookieSelected = false;
      }
    },
    toggleSelect(modalType) {
      switch (modalType) {
        case 1:
          this.isOperationCookieSelected = !this.isOperationCookieSelected;
          break;
        case 2:
          this.isPerformanceCookieSelected = !this.isPerformanceCookieSelected;
          break;
        case 3:
          this.isTrackingCookieSelected = !this.isTrackingCookieSelected;
          break;
      }
    },
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    closePlainTextModal() {
      this.isLegalWarningModalOpened = false;
      this.isDataPolicyModalOpened = false;
      this.isCookieModalOpened = false;
      this.setBottomBarStatus(true);
      document.getElementsByClassName('menu')[0].style.overflow = 'auto';
      document.getElementsByClassName('menu')[0].style.height = 'inherit';
    },
    openPlainTextModal(modalType) {
      this.setBottomBarStatus(false);
      document.getElementsByClassName('menu')[0].style.overflow = 'hidden';
      document.getElementsByClassName('menu')[0].style.height = '100%';
      if (modalType == 1) {
        StaticContent.getStaticContent('YasalUyari').then(res => {
          if (res.data.Data && res.data.Data.content) {
            this.legalWarningHtml = res.data.Data.content;
          }
        });
        this.isLegalWarningModalOpened = true;
      } else if (modalType == 2) {
        StaticContent.getStaticContent('VeriPolitikalari').then(res => {
          if (res.data.Data && res.data.Data.content) {
            this.dataPolicyHtml = res.data.Data.content;
          }
        });
        this.isDataPolicyModalOpened = true;
      } else if (modalType == 3) {
        this.isCookieModalOpened = true;
      }
    },
    getIcon(name) {
      return ICON_VARIABLES[name];
    },
    getParentMenuList() {
      this.isMenuRoot = true;
      this.$store.dispatch('app/setCurrentMenuItems', this.menuItems);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-cookies-wrapper {
  max-height: 400px;
  overflow: auto;
}
.modal-body-single-item {
  display: flex;
  flex-direction: column;
}
.modal-body-action-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding-bottom: palette-space-level('10');
  border-bottom: 1px solid black;
}

.modal-button,
.modal-button-active {
  margin-left: palette-space-level('10');
}

.used-cookies {
  text-decoration: underline;
}
.menu-wrapper {
  position: fixed;
  height: 100%;
  overflow: hidden;
  width: 100%;
  z-index: 25;
  padding-left: palette-space-level('50');
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
}
.menu {
  position: absolute;
  background-color: palette-color-level('white', '100');
  width: calc(100% - 50px);
  overflow: hidden;
  height: 100%;
  top: 0;
  right: 0;
  max-width: 375px;

  .menu-body {
    overflow: auto;
    height: calc(100% - 60px);
    padding-bottom: palette-space-level('80');
    text-align: -webkit-center;

    .menu-container {
      padding: 0 20px;
      &.brand-list {
        padding-top: palette-space-level('20');
      }
      &.menu-list {
        padding-bottom: palette-space-level('30') !important;
        /deep/ .single-list-item {
          align-items: center;
          padding: 19px 0;
        }
        a {
          /deep/ &.do-win-link {
            svg {
              margin-right: 12px !important;
            }
          }
        }
      }
    }
  }
}

.appbar-sticky {
  position: sticky;
  top: 0;
}

.menuBrandTitle {
  margin-bottom: palette-space-level('15');
}

.menuBrandWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: palette-space-level('5');
  .menuBrandItem {
    color: palette-color-level('grey', 40) !important;
    text-decoration: none !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 calc(50% - 2.5px);
    min-height: 90px;

    div {
      min-width: auto !important;
      width: 100% !important;
    }
    .brand-img-frame {
      overflow: hidden;
      border: solid 1px rgba(180, 194, 211, 0.5);
      padding: palette-space-level(15) palette-space-level(5);
      img {
        height: 60px;
        object-fit: scale-down;
      }
    }
  }
}

.pos-change {
  border-bottom: 0.5px solid #b4c2d3;
  font-weight: bold;
  padding: 0 0 25px;
  font-size: 16px;
  display: flex;
}

.logout-btn {
  border: 2px solid palette-color('red-50');
  font-size: 14px;
  display: flex;
  color: palette-color('red-50') !important;
  justify-content: center;
  border-radius: 40px;
  margin: 10px 0 0;
  width: 200px;

  &:hover {
    color: palette-color('red-50');
    border: 2px solid palette-color('red-50');
  }
}

.menuBrandBg {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.footerWrapper {
  padding-top: palette-space-level('30') !important;
  padding-bottom: palette-space-level('25') !important;
  border-radius: palette-radius-level('15');
  background-color: palette-color-level('grey', '10');
  margin-bottom: palette-space-level('20');
}
.footerTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerBottomRow {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerBottomRowText {
  line-height: 1.82;
  text-align: left;
  color: palette-color-level('grey', '20');
}
.footer-links-wrapper {
  display: flex;
  width: 100%;
}

.footerLink {
  text-decoration: underline;
  width: 100%;
  font-size: palette-font-size-level('2');
  white-space: nowrap;
  text-align: center;
  text-align: left;
  color: palette-color-level('grey', '30');
  &:first-child {
    padding-left: 0 !important;
  }
  &:last-child {
    padding-right: 0 !important;
    text-align: right !important;
  }
}

.footerBottomBox {
  border: 2px solid black;
  width: 100%;
  background-color: palette-color-level('white', 100);
  padding: palette-space-level('15') 0;
  margin: palette-space-level('20') 0;
  text-align: center;
  font-size: 14px;
  font-weight: 900;
}
.innerSlide-enter-active,
.innerSlide-leave-active {
  transition: all 0.3s ease-in-out;
}

.innerSlide-enter,
.innerSlide-leave-to {
  transform: translateX(100%);
}
</style>
