<template>
  <nav>
    <div
      v-if="getShapeVisible"
      class="shape"
      @click="
        closePopup(
          {
            event: 'cEvent',
            category: 'Pop Up',
            action: 'Close',
            label: getPopups.popupName,
            value: 0,
            isNonInteraction: false,
          },
          { type: 'BGClose' },
        )
      "
    />
    <MarathonPopup @closePopup="closePopup" />
    <UndeliveredAtomCallPopup @closePopup="closePopup" />
    <RosettePopup @closePopup="closePopup" />
    <AtomSurveyPopup @closePopup="closePopup" />
    <div
      class="main-nav-button-holder"
      v-if="customerDetail && customerDetail.isEOrderCustomer && !isIRPage"
    >
      <a @click="openEOrder()">
        <VueIcon
          :width="icons.cartOutline.width"
          :height="icons.cartOutline.height"
          :iconName="icons.cartOutline.name"
        ></VueIcon>
      </a>
    </div>
    <div class="main-nav-button-holder" v-if="isIRPage">
      <a @click="getLocationAndRedirect()">
        <VueIcon :width="23" :height="18" iconName="IconCameraBig"></VueIcon>
      </a>
    </div>
    <ul class="bottom-links">
      <li v-for="item in navItems" :key="item.url">
        <a
          v-if="item.code != 'eorder' && item.code !== 'takePhoto'"
          @click="goToURL(item.url, item.text, item.code)"
          :class="{ 'without-icon': !item.icon, 'dowin-btn': item.code === 'dowin' }"
          :style="getPopups.popupId == 4 ? { zIndex: 13 } : { zIndex: 10 }"
        >
          <span
            v-if="item.icon"
            :class="['icon-wrapper', { 'icon-wrapper-dowin': item.code === 'dowin' }]"
          >
            <span v-if="item.code === 'dowin' && activeDowinCount > 0" class="count-badge">
              {{ activeDowinCount }}</span
            >
            <span v-if="item.code === 'virtual-visit' && !watched" class="count-badge"> 1</span>
            <VueIcon
              v-if="item.code === 'virtual-visit' && watched"
              iconColor="#d02d2c"
              :width="item.watchedIcon.width"
              :height="item.watchedIcon.height"
              :iconName="item.watchedIcon.name"
              class="watched"
            />

            <VueIcon
              :width="item.icon.width"
              :height="item.icon.height"
              :iconName="item.icon.name"
              :color="item.color"
            ></VueIcon>
          </span>
          <span class="item-text" :style="{ color: item.color }">{{ item.text }}</span>
          <DoWinPopup @closePopup="closePopup" />
        </a>
        <a
          v-if="item.code === 'takePhoto'"
          @click="getLocationAndRedirect()"
          :class="{ 'without-icon': !item.icon }"
        >
          <span v-if="item.icon">
            <VueIcon
              :width="item.icon.width"
              :height="item.icon.height"
              :iconName="item.icon.name"
              :color="item.color"
            ></VueIcon>
          </span>
          <span class="item-text" :style="{ color: item.color }">{{ item.text }}</span>
        </a>
        <a
          class="virtual-visit-btn"
          v-if="item.code === 'virtual-visit'"
          @click.capture="goToURL(item.url, item.text)"
          :style="getPopups.popupId == 3 ? { zIndex: 12 } : { zIndex: 13 }"
        >
          <span v-if="item.icon" class="icon-wrapper">
            <span v-if="vvBadge" class="point-badge"></span>
            <VueIcon
              :width="item.icon.width"
              :height="item.icon.height"
              :iconName="item.icon.name"
              :color="item.color"
            ></VueIcon>
          </span>
          <span class="item-text" :style="{ color: item.color }">{{ item.text }}</span>
          <VirtualVisitPopup @closePopup="closePopup" />
        </a>
        <a
          class="e-order-btn"
          v-if="item.code == 'eorder' && customerDetail && customerDetail.isEOrderCustomer"
          @click="openEOrder()"
        >
          <span v-if="item.icon">
            <VueIcon
              :width="item.icon.width"
              :height="item.icon.height"
              :iconName="item.icon.name"
            ></VueIcon>
          </span>
          <span class="item-text">{{ item.text }}</span>
        </a>
      </li>
      <li>
        <a class="nav-menu-item" @click="setMenuStatus(!getMenuStatus)">
          <span class="icon-wrapper">
            <VueIcon
              :color="getMenuStatus ? '#e5472d' : 'currentColor'"
              iconName="IconJustifyRight"
            ></VueIcon>
          </span>
          <span :style="{ color: getMenuStatus ? '#e5472d' : 'currentColor' }" class="item-text"
            >Menu</span
          >
        </a>
      </li>
    </ul>
    <div class="notch-fixer" />
    <div v-if="!isIRUser" @click="openChatbot()" class="fab">
      <div class="bubble" v-if="showBubble">
        Merhaba! <br />
        Sana nasıl yardımcı olabilirim.
      </div>
    </div>
  </nav>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesImageRecognition from '@/router/routes/secure/RoutesImageRecognition';
import { mapGetters } from 'vuex';
import { Common } from '@/services/Api/index';
import StorageProps from '@/mixins/storageProps';
import gtmUtils from '@/mixins/gtmUtils';
import LocatinMixin from '@/utils/locationUtils.js';
import StorageHelper from '@/utils/storageHelper';
import AccountMixin from '@/utils/accountUtils.js';
import VirtualVisitPopup from '../../../views/pages/secure/popups/VirtualVisitPopup.vue';
import DoWinPopup from '../../../views/pages/secure/popups/DoWinPopup.vue';
import MarathonPopup from '../../../views/pages/secure/popups/MarathonPopup.vue';
import AtomSurveyPopup from '../../../views/pages/secure/popups/AtomSurveyPopup.vue';
import UndeliveredAtomCallPopup from '../../../views/pages/secure/popups/UndeliveredAtomCallPopup.vue';
import RosettePopup from '../../../views/pages/secure/popups/RosettePopup.vue';
import { EventLog } from '@/services/Api/index';
import { User } from '@/services/Api/index';
import { VirtualVisit } from '@/services/Api/index';

export default {
  name: 'BrandBottomNavigationBar',
  mixins: [StorageProps, gtmUtils, LocatinMixin, AccountMixin],
  components: {
    VueIcon,
    VirtualVisitPopup,
    DoWinPopup,
    MarathonPopup,
    AtomSurveyPopup,
    UndeliveredAtomCallPopup,
    RosettePopup,
  },
  data() {
    return {
      showBubble: false,
      chefShopAvailable: false,
      watched: true,
    };
  },
  mounted() {
    //initialize google ads for homepage
    this.pushAds('small');
    this.getVirtualVisitStatus();

    document.addEventListener('click', this.clickMinimizeChatButton);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickMinimizeChatButton);
  },
  computed: {
    ...mapGetters('app', ['getMenuStatus', 'getVvBadge', 'getActiveDowinCount', 'getMenuItems']),
    ...mapGetters('app', ['getPopups']),
    isVisibleCheckCampaigns() {
      if (!Array.isArray(this.getMenuItems)) return false;

      const targetScreenCode = 18;
      const hasTargetScreenCode = this.getMenuItems.some(item =>
        this.containsScreenCode(item, targetScreenCode),
      );

      return hasTargetScreenCode;
    },

    getShapeVisible() {
      return (
        !!this.getPopups.popupId &&
        this.getPopups.popupId != 6 &&
        this.$route.fullPath == '/secure/feed'
      );
    },
    activeDowinCount() {
      let count =
        this.getActiveDowinCount ||
        new StorageHelper({
          id: process.env.VUE_APP_STORAGE_TASK_COUNT,
        }).get();
      return count;
    },
    vvBadge() {
      let badge =
        this.getVvBadge ||
        new StorageHelper({
          id: process.env.VUE_APP_VIRTUAL_VISIT_BADGE,
        }).get();
      return badge;
    },
    isIRPage() {
      return this.$route.path.includes('image-recognition');
    },
    isIRUser() {
      const user = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).get();
      return user.isImageRecognitionUser;
    },
    icons() {
      return ICON_VARIABLES;
    },
    navItems() {
      return !this.isIRPage && this.isPartner
        ? [
            {
              code: 'home',
              url: `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`,
              text: 'Anasayfa',
              icon: ICON_VARIABLES.home,
              color:
                this.getActiveNavItem == 'home' && !this.getMenuStatus ? '#e5472d' : 'currentColor',
            },
            {
              code: 'dowin',
              url: `${RoutesRoot.Secure.path}/${RoutesSecure.DoAndWin.path}`,
              popupLink:
                '/secure/do-and-win?utm_source=pmaktif&utm_medium=pop-up&utm_campaign=automated&utm_id=YapKazan+Main+Pop-up',
              text: 'Yap Kazan',
              icon: ICON_VARIABLES.wave,
              color:
                this.getActiveNavItem == 'dowin' && !this.getMenuStatus
                  ? '#e5472d'
                  : 'currentColor',
            },
            {
              code: 'eorder',
              url: '/e-siparis',
              text: 'Ön-Sipariş',
            },
            this.isVisibleCheckCampaigns
              ? {
                  code: 'virtual-visit',
                  url: `${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`,
                  popupLink:
                    '/secure/virtual-visit?utm_source=pmaktif&utm_medium=pop-up&utm_campaign=automated&utm_id=Virtual+Visit+Main+Pop-up',
                  text: 'Şef Dükkanında',
                  icon: ICON_VARIABLES.virtualVisit,
                  watchedIcon: ICON_VARIABLES.checked,
                  color:
                    this.getActiveNavItem == 'virtual-visit' && !this.getMenuStatus
                      ? '#e5472d'
                      : 'currentColor',
                }
              : [],
          ]
        : !this.isIRPage && !this.isPartner
        ? [
            {
              code: 'home',
              url: `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`,
              text: 'Anasayfa',
              icon: ICON_VARIABLES.home,
              color:
                this.getActiveNavItem == 'home' && !this.getMenuStatus ? '#e5472d' : 'currentColor',
            },
            {
              code: 'eorder',
              url: '/e-siparis',
              text: 'Ön-Sipariş',
            },
          ]
        : [
            {
              code: 'home',
              url: this.isIRUser
                ? `${RoutesRoot.Secure.path}/${RoutesSecure.ImageRecognition.path}`
                : `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`,
              text: 'Anasayfa',
              icon: ICON_VARIABLES.home,
              color:
                this.getActiveNavItem == 'home' && !this.getMenuStatus ? '#e5472d' : 'currentColor',
            },
            {
              code: 'takePhoto',
              url: `${RoutesRoot.Secure.path}/${RoutesSecure.ImageRecognition.path}/${RoutesImageRecognition.Main.path}`,
              text: 'Fotoğraf Çek',
            },
          ];
    },
  },
  methods: {
    clickMinimizeChatButton(event) {
      const isMinimizeChatButton = event.target.classList.contains('mwc-flow-minimize');
      if (isMinimizeChatButton) {
        const webChatContainer = document.getElementById('MB_WEBCHAT_WIDGET');

        if (webChatContainer?.style) {
          webChatContainer.style.zIndex = 1;
        }
      }
    },
    toggleChatbot(value) {
      const user = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).get();
      if (user.isImageRecognitionUser) return;
      const webChatId = document.getElementById('MB_WEBCHAT_WIDGET');
      if (webChatId?.style) webChatId.style.display = value ?? 'none';
    },
    containsScreenCode(item, targetScreenCode) {
      if (item.screenCode === targetScreenCode) {
        return true;
      } else if (item.children && item.children.length > 0) {
        return item.children.some(childItem =>
          this.containsScreenCode(childItem, targetScreenCode),
        );
      } else {
        return false;
      }
    },
    setPopupGAEvent(eventData) {
      this.pushDataLayerEvent('pop-up', eventData);
    },
    closePopup(eventValue, action) {
      if (eventValue) {
        this.setPopupGAEvent(eventValue);
      }
      const popupId = this.getPopups.popupId;
      const popupDetail = this.getPopups.popupDetail;

      const request = {
        popupId,
        popupDetailObjectKey:
          popupId == 4 || popupId == 5 ? (popupId == 4 ? 'TaskId' : 'SurveyId') : null,
        popupDetailObjectValue:
          popupId == 4 || popupId == 5
            ? popupId == 4
              ? popupDetail.taskId?.toString() ?? ''
              : popupDetail.surveyId?.toString() ?? ''
            : null,
        actionType: action.type,
      };
      Common.viewPopup(request);
      this.$store.dispatch('app/setPopups', {});
    },
    goToURL(url, name, code) {
      this.toggleChatbot('none');
      if (this.$route.fullPath === url) {
        this.$router.go();
      } else {
        if (code === 'dowin') {
          this.pushDataLayerEvent('ecommerce');
          this.pushDataLayerEvent('yap_kazan', {
            location: 'navbar',
          });
        } else if (code === 'virtual-visit') {
          this.pushDataLayerEvent('ecommerce');
          this.pushDataLayerEvent('sef_dukkaninda', {
            location: 'navbar',
          });
        } else {
          this.pushDataLayerEvent('ecommerce');
          this.pushDataLayerEvent('menu_click', {
            type: name,
          });
        }
        this.$router.push(url);
      }
    },
    setMenuStatus(status) {
      this.$store.dispatch('app/setMenuStatus', status);
      this.toggleChatbot('none');
    },
    openChatbot() {
      EventLog.postEventLog(this.$route.path, window.location.href);
      User.newsessionforChatbot();

      this.pushDataLayerEvent('chatbot');
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('sen_sor', {
        location: 'navbar',
      });
      this.toggleChatbot('block');

      const chatBotEl = document.querySelectorAll('.container__CompanyIcon-sc-qtelua-10');
      chatBotEl.forEach(element => {
        element.click();
      });

      const webChatContainer = document.getElementById('MB_WEBCHAT_WIDGET');

      if (webChatContainer?.style) {
        webChatContainer.style.zIndex = 999999999;
      }
    },
    openEOrder() {
      this.toggleChatbot('none');
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('on_siparis_click', {
        location: 'navbar',
      });
      Common.getEcommerceUrl().then(res => {
        const {
          Data: { url },
        } = res.data;
        window.location = url;
      });
    },
    hideBubble() {
      let show = new StorageHelper({ id: process.env.VUE_APP_STORAGE_SHOW_BUBBLE }).get();
      if (!show) {
        this.showBubble = true;
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_SHOW_BUBBLE }).set(true);
        setTimeout(() => {
          this.showBubble = false;
        }, 5000);
      }
    },
    getVirtualVisitStatus() {
      VirtualVisit.getVirtualVisitStatus().then(res => {
        this.watched = res.data.Data.isWatch;
      });
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  height: 100%;
  position: relative;
  background: transparent url('~@/assets/bg/bg-navbar.svg') top center no-repeat;
  background-size: cover;

  .notch-fixer {
    padding-bottom: #{$safe-area-inset-bottom};
    background-color: palette-color-level('white', 100);
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    max-height: calc(100% - #{$safe-area-inset-bottom} / 2);
    width: 100%;

    li {
      flex: 1;
      height: 100%;

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        min-height: 100%;
        text-decoration: none;
        color: palette-color-level('grey', 30);
        padding: palette-space-level(5) 0;

        &.nav-menu-item {
          .icon-wrapper {
            svg {
              position: relative;
              top: 3px;
            }
            img {
              position: absolute;
              right: 0;
              bottom: 13px;
            }
          }
        }
        &.without-icon {
          justify-content: flex-end;
        }

        .icon-wrapper,
        .item-text,
        .count-badge {
          font-size: palette-font-size-level(2);
          justify-content: center;
          align-items: center;
          display: flex;
          flex: 1;
        }
        .item-text {
          flex: initial;
          justify-self: flex-end;
          white-space: nowrap;
        }
      }
    }
  }

  .main-nav-button-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: $bottom-bar-height;
    padding-bottom: palette-space-level(5);
    // border-radius: $app-border-radius-rounded;
    transform: translateX(-50%) translateY(-100%);
    color: palette-color-level('white', 100);
    a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: palette-color-level('white', 100);
      @include bg-linear-gradient(
        315deg,
        palette-color-level('fushia', 20) 0%,
        palette-color-level('yellow', 30) 100%
      );
    }
  }
}
.virtual-visit-btn,
.e-order-btn {
  position: relative;
  left: 2px;
  @media screen and (max-width: 359px) {
    left: 4px;
  }
}
.dowin-btn {
  position: relative;
  z-index: 13;
}
.fab {
  position: absolute;
  top: -80px;
  right: 18px;
  width: 68px;
  height: 68px;
  background-image: url('~@/assets/images/ef.svg');
  background-position: center;
  border-radius: 50%;
  z-index: 3;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  .bubble {
    position: absolute;
    min-width: 145px;
    text-align: center;
    top: -86px;
    right: 3px;
    font-size: 12px;
    padding: 14px 18px;
    border-radius: 20px;
    height: 83px;
    background-image: url('~@/assets/images/bubble.png');
    background-position-x: -2px;
    background-repeat: no-repeat;
  }
}
.icon-wrapper {
  position: relative;
  &.icon-wrapper-dowin {
    svg {
      position: relative;
      top: 2px;
    }
  }
  .point-badge,
  .count-badge {
    content: '';
    position: absolute;
    background-color: #e5472d;
    border-radius: 50%;
    border: 2px solid #fff;
    z-index: 2;
  }
  .point-badge {
    top: 2px;
    right: -3px;
    width: 10px;
    height: 10px;
  }
  .count-badge {
    top: -2px;
    right: -2px;
    width: 18px;
    height: 18px;
    color: #fff;
    font-size: 9px;
  }
  .watched {
    position: absolute;
    width: 14px;
    top: -2px;
    right: -2px;
  }
}
.shape {
  position: absolute;
  top: -1024px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  opacity: 0.8;
  z-index: 11;
}
</style>
